import React from 'react';
import { Box } from '@chakra-ui/react';
import HistoryTable from './HistoryTable/HistoryTable';

// Define your props interface
interface CampaignHistoryProps {
}

const CampaignHistory: React.FC<CampaignHistoryProps> = ({ }) => {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <HistoryTable/>
    </Box>
  );
}

export default CampaignHistory;
