import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner, // Import Spinner for loading indication
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth2.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useApiContext } from "contexts/apiContext";
import { signIn } from "libs/apiFunctions";
import { toast } from "react-toastify";
import { useAuthContext } from "contexts/authContext";


interface formDataType {
  email: string;
  password: string;
  [key: string]: string | boolean; // Adding index signature to support dynamic key access
}


function ForgotPassword() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "black";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const { setUserData } = useApiContext();

  const [formData, setFormData] = useState<formDataType>({
    email: '',
    password: ''
  })
  const [loading, setLoading] = useState(false); // Loading state
  const history = useHistory();

  const { isLoggedIn, setLogin } = useAuthContext();

  const [errors, setErrors] = useState({
    email: "",
    password: ""
  });
  const validateField = (name: string, value: any): string => {
    let error = '';
    switch (name) {
      case 'email':
        if (!value) {
          error = 'Please enter email address.';
        } else
          if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
            error = 'Please enter a valid email address.';
          }
        break;
      case 'password':
        if (!value || value.trim() === '') {
          error = 'Please enter password.';
        }
        break;
      default:
        error = '';
    }
    return error;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: error,
    });
  };


  // Handle form submission
  const handleSubmit = async () => {
    const newErrors: any = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      let response = await signIn(formData.email, formData.password);
      console.log("response", response)
      if (response?.statusMessage === "SUCCESS") {
        setUserData(response?.data);
        sessionStorage.setItem('accessToken', response?.token)
        sessionStorage.setItem('userData', JSON.stringify(response?.data))
        setLogin(true)
        sessionStorage.setItem('isLoggedIn', "true");
        setTimeout(() => {
          setLoading(false);
          toast.success(response?.message);
          history.push('/admin/default');
        }, 1000);
      } else {
        setLoading(false);
        toast.info(response.message);
      }
    }
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "40px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "50px", md: "35vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Forgot Password
          </Heading>
        
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>


          <FormControl>
            <Box my={1.5}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color='red'>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='Please enter email address'
              fontWeight='500'
              size='lg'
              name="email"
              value={formData.email} // Bind value to state
              onChange={handleInputChange} // Handle change
            />
            {errors.email && <Box color="red.500" py={0.5}>{errors.email}</Box>}
            </Box>

    
            <Flex justifyContent='space-between' align='center' mb='10px'>
              <FormControl display='flex' alignItems='center'>
                {/* <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel> */}
              </FormControl>
        
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleSubmit} // Handle form submission
            >
             Reset Password 
            </Button>
          </FormControl>
       
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword;
