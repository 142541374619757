import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { modules, formats } from './QuillToolbar'; // Adjust path as necessary
import QuillToolbar from './QuillToolbar'; // Adjust path as necessary
import './Editor.css'; // Adjust the path as necessary

interface EmailDetailsProps {
  handleBody: (value: string) => void;
  body: string;
}

const Editor: React.FC<EmailDetailsProps> = ({ handleBody, body }) => {
  const quillRef = React.useRef<ReactQuill>(null); // Create a ref for the Quill instance

  return (
    <div>
      <QuillToolbar quillRef={quillRef} />
      <div className="editor-container">
        <ReactQuill
          ref={quillRef}
          value={body}
          onChange={handleBody}
          modules={modules}
          formats={formats}
        />
      </div>
    </div>
  );
};

export default Editor;
