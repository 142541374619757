import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdContactMail,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdCampaign,
  MdEmail,
  MdSettings,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Contacts from "views/admin/contacts";
import Campaigns from "views/admin/campaigns";
import SendEmail from "views/admin/sendemail";
import SendingsSchedules from "views/admin/sendingschedules";
import EmailAccounts from "views/admin/emailaccounts";
import AddEmail from "views/admin/addemail";
import EditEmail from "views/admin/editemail";
import CreateCampaigns from "views/admin/createcampaigns";
import ForgotPassword from "views/auth/forgotpassword";
import ResetPassword from "views/auth/resetpassword";
import SignUp from "views/auth/signup";
import SignIn from "views/auth/signIn";
import CampaignHistory from "views/admin/campaignhistory";
import GlobalContactList from "views/admin/globalcontactlist";

const handleBackToDefault = () => {
  // Your implementation
};

const iconSize = { width: "20px", height: "20px" }; // Standardized icon size

const routes: RoutesType[] = [
  {
    name: "Campaigns List",
    layout: "/admin",
    path: "/campaigns",
    icon: <Icon as={MdCampaign} {...iconSize} color="inherit" />,
    component: Campaigns,
    secondary: true,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings", // Changed to a unique path for the parent route
    icon: <Icon as={MdSettings} {...iconSize} color="inherit" />,
    component: Campaigns, // You can use a dummy component or create a separate settings component
    children: [
      {
        name: "Sending Schedules",
        layout: "/admin",
        path: "/sendingschedules",
        icon: (
          <Icon
            as={MdOutlineShoppingCart}
            {...iconSize}
            color="inherit"
          />
        ), // Add an icon
        component: SendingsSchedules,
      },
      {
        name: "Email Accounts",
        layout: "/admin",
        path: "/emailaccounts",
        icon: (
          <Icon
            as={MdOutlineShoppingCart}
            {...iconSize}
            color="inherit"
          />
        ), // Add an icon
        component: EmailAccounts,
      },
    ],
  },
  {
    name: "Global Contact List",
    layout: "/admin",
    path: "/globalcontactlist",
    icon: <Icon as={MdContactMail} {...iconSize} color="inherit" />,
    component: GlobalContactList,
    secondary: true,
  },
  {
    name: "Add Email",
    layout: "/admin",
    path: "/addemail",
    icon: <Icon as={MdEmail} {...iconSize} color="inherit" />,
    component: AddEmail,
    secondary: true,
  },
  {
    name: "Create Campaign",
    layout: "/admin",
    path: "/createcampaign",
    icon: <Icon as={MdEmail} {...iconSize} color="inherit" />,
    component: () => <CreateCampaigns />, // Wrap the component call
    secondary: true,
  },
  {
    name: "Campaign History",
    layout: "/admin",
    path: "/campaignhistory",
    icon: <Icon as={MdEmail} {...iconSize} color="inherit" />,
    component: () => <CampaignHistory />, // Wrap the component call
    secondary: true,
  },
  {
    name: "Edit Campaign",
    layout: "/admin",
    path: "/editcampaign",
    icon: <Icon as={MdEmail} {...iconSize} color="inherit" />,
    component: () => <CreateCampaigns />, // Wrap the component call
    secondary: true,
  },
  {
    name: "Edit Email",
    layout: "/admin",
    path: "/editemail",
    icon: <Icon as={MdEmail} {...iconSize} color="inherit" />,
    component: EditEmail,
    secondary: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} {...iconSize} color="inherit" />,
    component: SignIn,
    secondary: true,
    hidden: true,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} {...iconSize} color="inherit" />,
    component: SignUp,
    secondary: true,
    hidden: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} {...iconSize} color="inherit" />,
    component: ForgotPassword,
    secondary: true,
    hidden: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} {...iconSize} color="inherit" />,
    component: ResetPassword,
    secondary: true,
    hidden: true,
  },
];

export default routes;

declare global {
  interface RoutesType {
    name: string;
    layout: string;
    path: string;
    icon: JSX.Element;
    component: () => JSX.Element;
    children?: RoutesType[];
    secondary?: boolean;
    hidden?: boolean;
  }
}
