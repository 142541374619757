export function convertToISOFormat(timeString) {
  const [hours, minutes] = timeString.split(":");
  const date = new Date();
  date.setUTCFullYear(2024, 11, 31);
  date.setUTCHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
  return date.toISOString(); 
}



export const convertTimeToInputFormat = (time) => {
  const [hour, minute, secondPeriod] = time.split(/[: ]/);
  const period = secondPeriod.slice(-2);
  let hourIn24Format = parseInt(hour, 10);

  if (period === "PM" && hourIn24Format !== 12) {
    hourIn24Format += 12;
  } else if (period === "AM" && hourIn24Format === 12) {
    hourIn24Format = 0;
  }

  const date = new Date();
  date.setHours(hourIn24Format, minute);
  return date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });
};

export const convertTo24HourFormat = (time) => {
  const [hourMinute, period] = time.split(" ");
  const [hour, minute] = hourMinute.split(":");
  let hourInt = parseInt(hour, 10);
  if (period === "PM" && hourInt !== 12) hourInt += 12;
  if (period === "AM" && hourInt === 12) hourInt = 0;
  return `${hourInt.toString().padStart(2, "0")}:${minute}`;
};



