import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  IconButton,
  TableContainer,
  TabPanel,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Switch,
  Input,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaHistory, FaPlus, FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import AddCampaignDefault from "../AddCampaignDefault/AddCampaignDefault";
import { getAllCampaigns } from "libs/apiFunctions";
import ScratchCampaigns from "../../../createcampaigns/ScratchCampaigns/ScratchCampaigns";
import { FaEye, FaTrash } from "react-icons/fa"; // Importing icons
import { changeCampaignStatus, deleteCampaign } from "libs/campaignApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DeleteConfirmationToast from "components/toast/DeleteConfirmationToast";

const CampaignsTable = (props: { tableData: any }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "primary.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  const { tableData } = props;
  const [campaingsData, setCampaignsData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showScratch, setShowScratch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const fetchCampain = async () => {
    setLoading(true);
    let response = await getAllCampaigns(currentPage, searchText);
    try {
      if (response.status === 200) {
        setCampaignsData(response?.data?.campaigns);
        setTotalPage(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      } else {
        setCampaignsData([]);
      }
    } catch (error) {
      setCampaignsData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampain();
  }, [currentPage, searchText]);

  const handleAddCampaign = (newCampaign: any) => {
    setCampaignsData((prevCampaigns) => [...prevCampaigns, newCampaign]);
  };

  const handleSwitchToScratch = () => {
    setShowScratch(true);
  };

  const handleBackToDefault = () => {
    setShowScratch(false);
  };

  const handleDelete = async (id: string) => {
    DeleteConfirmationToast({
      message: "Are you sure you want to delete campaign",
      // successMessage: "Ca",
      errorMessage: "Something went wrong",
      onConfirm: async () => {
        const response = await deleteCampaign(id);
        if (response.status == 200) {
          toast.success(response?.data?.message);
          fetchCampain();
        } else {
          toast.error(response?.statusText);
        }
      },
      // onCancel: () => console.log("Deletion cancelled")
    });
  };
  const history = useHistory();
  const handleFromScratch = (id: string) => {
    history.push("/admin/createcampaign");
    sessionStorage.setItem("campaignId", id);
    sessionStorage.setItem("campaignEdit", "true");
  };

  const handleHistory = (id:string) => {
    history.push("/admin/campaignhistory");
    sessionStorage.setItem("campaignHistory", id)
  }
  const truncateText = (text: any, limit: any) => {
    if (!text) return "";
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  const handleStatus =async(id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    let response = await changeCampaignStatus(id, value);
    try {
      if (response.status == 200) {
        fetchCampain()
        toast.success(response.data.message)
      } else {
        toast.error(response.message || response.data.message)
      }
    } catch (error) {
    }
  }


  return (
    <>
      <Box px="0px" justifyContent="space-between" my="0px">
        {!showScratch && (
          <Box
            borderRadius="16px"
            // border='1px solid'
            p={4}
            bgColor="#fff"
            overflow="hidden"
          >
            <Tabs variant="brand">
              <TabList sx={{ display: "flex", justifyContent: "end" }}>
                <Box display="flex" alignItems="center">
                  <Button
                    alignItems="center"
                    justifyContent="center"
                    variant="brand"
                    mx={2}
                    color="white"
                    lineHeight="100%"
                    borderRadius="30px"
                    onClick={() => {
                      // setIsOpen(true)
                      history.push("/admin/createcampaign");
                      sessionStorage.removeItem("campaignEdit");
                      sessionStorage.removeItem("campaignId");
                    }}
                  >
                    <Icon as={FaPlus} color="white" w="20px" h="20px" mr="2" />
                    Create Campaign
                  </Button>
                </Box>
                <Box display="flex" alignItems="center">
                  <Input
                    placeholder="Search..."
                    borderRadius="30px"

                    value={searchText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchText(e.target.value)
                    }
                  />
                </Box>

              </TabList>

              <TabPanels p="0px!important">
                <TabPanel p="0px!important">
                  {loading && (
                    <Flex justify="center" align="center" height="200px">
                      <Spinner size="lg" />
                    </Flex>
                  )}

                  {campaingsData.length == 0 && !loading && (
                    <Flex justify="center" align="center" height="200px">
                      <Text ml="4" fontSize="lg" color="#222">
                        No Data Found
                      </Text>
                    </Flex>
                  )}

                  {campaingsData.length > 0 && !loading && (
                    <TableContainer mt={8}>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Status
                            </Th>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Name
                            </Th>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Description
                            </Th>
                            {/* <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >Sender Name</Th>
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >Scheduled Send Time</Th> */}
                            <Th
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="black"
                            >
                              Action
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {campaingsData?.map((campaign, index) => (
                            <Tr key={index}>
                              <Td fontSize="sm" fontWeight="700">
                                <Flex alignItems={"center"}>
                                  <Switch
                                    mx={2}
                                    id="email-alerts"
                                    colorScheme="brand"
                                    isChecked={campaign.isActive}
                                    onChange={(e) => handleStatus(campaign._id, e)}
                                  />
                                </Flex>
                              </Td>
                              <Td fontSize="sm" fontWeight="500">
                                {campaign?.name}
                              </Td>

                              <Td fontSize="sm" fontWeight="500">
                                {truncateText(campaign?.notes, 30)}{" "}
                                {/* Truncate after 30 characters */}
                              </Td>

                              {/* <Td
                                fontSize="sm" fontWeight="700"
                              >{campaign.senderName}</Td>
                              <Td
                                fontSize="sm" fontWeight="700"
                              >{campaign.scheduledSendTime?.split("T")[0]}</Td> */}
                              <Td fontSize="sm" fontWeight="700">
                                <Flex>
                                  {/* History Button */}
                                  <Flex>
                                    <Tooltip label="History" borderRadius={8} fontSize="sm">
                                      <Button
                                        onClick={() => handleHistory(campaign._id)}
                                        alignItems="center"
                                        justifyContent="center"
                                        bg={bgButton}
                                        _hover={bgHover}
                                        _focus={bgFocus}
                                        _active={bgFocus}
                                        w="37px"
                                        h="37px"
                                        lineHeight="100%"
                                        mx={2}
                                        ms={0}
                                        borderRadius="10px"
                                      >
                                        <Icon as={FaHistory} color={iconColor} w="20px" h="20px" />
                                      </Button>
                                    </Tooltip>
                                  </Flex>
                                  {/* Edit Button */}
                                  <Flex alignItems={"center"}>
                                    <Tooltip label="Edit" borderRadius={8} fontSize="sm">
                                      <Button
                                        alignItems="center"
                                        justifyContent="center"
                                        bg={bgButton}
                                        _hover={bgHover}
                                        _focus={bgFocus}
                                        _active={bgFocus}
                                        w="37px"
                                        h="37px"
                                        lineHeight="100%"
                                        me={2}
                                        borderRadius="10px"
                                        onClick={() => handleFromScratch(campaign?._id)}
                                      >
                                        <Icon as={FaRegEdit} color={iconColor} w="20px" h="20px" />
                                      </Button>
                                    </Tooltip>
                                  </Flex>

                                  {/* Delete Button */}
                                  <Flex>
                                    <Tooltip label="Delete" borderRadius={8} fontSize="sm">
                                      <Button
                                        alignItems="center"
                                        justifyContent="center"
                                        bg={bgButton}
                                        _hover={bgHover}
                                        _focus={bgFocus}
                                        _active={bgFocus}
                                        w="37px"
                                        h="37px"
                                        lineHeight="100%"
                                        mx={2}
                                        ms={0}
                                        borderRadius="10px"
                                        onClick={() => handleDelete(campaign?._id)}
                                      >
                                        <Icon as={FaRegTrashAlt} color={iconColor} w="20px" h="20px" />
                                      </Button>
                                    </Tooltip>
                                  </Flex>


                                </Flex>
                              </Td>

                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  )}
                </TabPanel>
                <TabPanel>
                  <p>two!</p>
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
            {campaingsData.length > 0 && !loading && (
              <HStack spacing={4} mt={4} justifyContent="center">
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || loading}
                >
                  Previous
                </Button>
                {[...Array(totalPage).keys()].map((page) => (
                  <Button
                    key={page}
                    onClick={() => handlePageChange(page + 1)}
                    colorScheme={currentPage === page + 1 ? "blue" : "gray"}
                  >
                    {page + 1}
                  </Button>
                ))}
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPage || loading}
                >
                  Next
                </Button>
              </HStack>
            )}
          </Box>
        )}

        {/* {showScratch && (
          <Box>
            <Button
              onClick={handleBackToDefault}
              colorScheme="blue"
              mb="1"
              leftIcon={<ArrowBackIcon />}
            >
              Back to Previous
            </Button>
            <ScratchCampaigns handleBackToDefault={handleBackToDefault} />
          </Box>
        )} */}
      </Box>

      <AddCampaignDefault
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAddCampaign={handleAddCampaign}
        fetchCampain={fetchCampain}
        onSwitchToScratch={handleSwitchToScratch}
      />
    </>
  );
};

export default CampaignsTable;
