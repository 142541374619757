import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TableContainer,
  TabPanel,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Switch,
  Input,
} from "@chakra-ui/react";
import { FaPlus, FaRegEdit, FaRegTrashAlt, FaHistory, FaBackward, FaArrowLeft } from "react-icons/fa";
import { getAllCampaigns, getAllContactData } from "libs/apiFunctions";
import { deleteCampaign } from "libs/campaignApi";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import DeleteConfirmationToast from "components/toast/DeleteConfirmationToast";

const ContactListTable = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue({ bg: "primary.100" }, { bg: "whiteAlpha.50" });
  const bgFocus = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.100" });

  const [campaignsData, setCampaignsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const history = useHistory();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await getAllContactData(searchText, currentPage);
      if (response.status === 200) {
        setCampaignsData(response?.data?.contacts || []);
        setTotalPage(response.data.totalPages || 1);
        setCurrentPage(response.data.currentPage || 1);
      } else {
        setCampaignsData([]);
      }
    } catch (error) {
      setCampaignsData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [currentPage, searchText]);


  return (
    <Box px="0px" my="0px">
      <Box borderRadius="16px" p={4} bgColor="#fff" overflow="hidden">
        <Tabs variant="brand">
          <TabList display="flex" flexDirection="row" justifyContent="flex-end">
            <Input
              placeholder='Search...'
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setCurrentPage(1);
              }}
              borderRadius='30px'
              w='200px'
              mx='10px'
              alignItems="flex-end"
            />
          </TabList>

          <TabPanels>
            <TabPanel>
              {loading && (
                <Flex justify="center" align="center" height="200px">
                  <Spinner size="lg" />
                </Flex>
              )}
              {!loading && campaignsData.length === 0 && (
                <Flex justify="center" align="center" height="200px">
                  <Text fontSize="lg" color="#222">
                    No Data Found
                  </Text>
                </Flex>
              )}
              {!loading && campaignsData.length > 0 && (
                <TableContainer mt={2}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>First Name</Th>
                        <Th>Last Name</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>Website Domain</Th>

                      </Tr>
                    </Thead>
                    <Tbody>
                      {campaignsData.map((campaign) => (
                        <Tr key={campaign._id}>
                          <Td>{campaign.firstName || "N/A"}</Td>
                          <Td>{campaign.lastName || "N/A"}</Td>
                          <Td>{campaign.email || "N/A"}</Td>
                          <Td>{campaign.phone || 0}</Td>
                          <Td>{campaign.websiteDomain || "N/A"}</Td>

                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        {campaignsData.length > 0 && (
          <HStack mt={4} justifyContent="center">
            <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            {[...Array(totalPage).keys()].map((page) => (
              <Button
                key={page}
                onClick={() => handlePageChange(page + 1)}
                colorScheme={currentPage === page + 1 ? "blue" : "gray"}
              >
                {page + 1}
              </Button>
            ))}
            <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage}>
              Next
            </Button>
          </HStack>
        )}
      </Box>
    </Box>
  );
};

export default ContactListTable;
