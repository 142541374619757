import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Icon,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  useColorModeValue,
  Tooltip,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Switch,
  IconButton,
  Flex,
  Spinner,
  FormErrorMessage,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, CopyIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./AddSteps.css";
import { addStep, deleteStep, getAllStep, getStepById, updateStep, uploadImage } from "libs/stepApi";
import { toast } from "react-toastify";
import { getOptions } from "libs/campaignApi";
import Editor from "common/Editor/Editor";
import Modal from 'react-modal';
import he from "he";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import AddCampaignStep from "../AddCampaignStep/AddCampaignStep";
import parse from 'html-react-parser';


const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.48)',
    zIndex: 99999,

  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    maxHeight: '80vh', // Add max height to allow scrolling
    overflowY: 'auto' as 'auto', // Cast it explicitly as 'auto' to resolve type error
  },
};





// Define the props type for StepIcon
interface StepIconProps {
  stepCount: number;
}
interface CampaignStep {
  campaignId: string;
  stepNumber: number;
  days: number;
  dateOfSendMail: string;
  isActive: boolean;
  isDeleted: boolean;
  campaignStepId: string;
  campaignStepTemplateId: string;
  eModProfileId: string;
  body: string;
  subject: string;
}
interface CampaignOption {
  _id: string;
  fieldTypeName: string;
  fieldID: number;
  fieldName: string;
  comment: string | null;
  isDeleted: boolean;
  isActive: boolean; // Ensure this is defined
  orderBy: number;
  createdDate: string;
  lastModifiedDate: string;
}
interface NewStep {
  waitTime: string;
  subject: string,
  [key: string]: string | boolean;
}

interface SelectedFields {
  emodProfileId: string;
}
const optionConfigs = [
  { key: "eModProfile", label: "eMod Profile" }
];


// Custom Icon Component
const StepIcon: React.FC<StepIconProps> = ({ stepCount }) => (
  <Box
    borderRadius="50%"
    backgroundColor="#3965FF"
    color="#fff"
    display={"flex"}
    margin={"auto"}
    alignItems={"center"}
    justifyContent={"center"}
    fontWeight={"bold"}
    w={"100%"}
    h={"100%"}
  >
    {stepCount}
  </Box>
);

const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline'],
      ['link', 'image'],
    ],
  },
};
interface EmailDetailsProps {
  setActiveStep: (step: number) => void;
  activeStep: number
}

const AddSteps: React.FC<EmailDetailsProps> = ({ setActiveStep, activeStep }) => {


  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgHover = useColorModeValue(
    { bg: "primary.100" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [waitTime, setWaitTime] = useState("");
  const [emodProfile, setEmodProfile] = useState("");
  const [selectedFields, setSelectedFields] = useState<SelectedFields>({
    emodProfileId: ""
  });

  const [loading, setLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(false);
  const [stepData, setStepData] = useState<CampaignStep[]>([])


  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }






  const [newStep, setNewStep] = useState<NewStep>({
    waitTime: '',
    subject: "",
  });
  const [errors, setErrors] = useState({
    waitTime: '',
    subject: "",
    body: "",
    emodProfileId: false,
  });
  const handleClear = () => {
    setNewStep({
      waitTime: '',
      subject: "",
    });
    setBody("");
    setSelectedFields({
      emodProfileId: ""
    });
    setErrors({
      waitTime: '',
      subject: "",
      body: "",
      emodProfileId: false,
    })
    setError({
      emodProfileId: ""
    })
  };


  const validateField = (name: string, value: any): string => {
    let error = '';

    switch (name) {
      case 'subject':
        if (!value && stepData.length == 0) {
          error = 'Please enter subject';
        }
        break;
      case 'waitTime':
        if (!/^\d+$/.test(value)) {
          error = 'Please enter valid wait time';
        }
        break;
      case 'emodProfileId':
        if (!value) {
          error = 'Please select eMod';
        }
        break;

      default:
        error = '';
    }
    return error;
  };

  const [steps, setSteps] = useState<
    {
      waitTime: string;
      subject: string;
      emodProfile: string;
      body: string;
      date: string;
      active: boolean;
    }[]
  >([]);

  const [error, setError] = useState<{ emodProfileId?: string }>({});
  const validateEmodForm = () => {
    const newError: { emodProfileId?: string } = {};
    if (!selectedFields.emodProfileId) {
      newError.emodProfileId = "Please select an eMod profile.";
    }
    setError(newError);
    return Object.keys(newError).length === 0;
  };

  const handleSave = async () => {
    await validateEmodForm()
    const newErrors: any = {};
    Object.keys(newStep).forEach((key) => {
      const error = validateField(key, newStep[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && error.emodProfileId.length == 0 && selectedFields.emodProfileId.length > 0) {
      setLoadingStep(true)
      let campId = sessionStorage.getItem('campaignId')
      const stepNumber = stepData.length + 1
      const response = await addStep(newStep, selectedFields, campId, stepNumber, body)
      try {
        if (response.status == 200) {
          toast.success("Step Added Successfully")
          closeModal();
          fetchStep()
          handleClear()
        } else {
          toast.error(response.statusText)
          handleClear()
        }
      } catch (error) {
      } finally {
        setLoadingStep(false)
      }
    }
  };

  const handleToggle = (index: number) => {
    setSteps(
      steps.map((step, i) =>
        i === index ? { ...step, active: !step.active } : step
      )
    );
  };
  const [editModal, setEditModal] = useState(false)
  const [editStepId, setEditStepId] = useState("")
  const [stepNumber, setStepNumber] = useState<Number>(0)
  const handleEdit = async (id: string, stepNo: Number) => {
    setStepNumber(stepNo)
    try {
      handleClear()
      const response = await getStepById(id);
      if (response.status === 200) {
        setEditStepId(id)
        setEditModal(true)
        openModal();
        const dat = response.data[0];
        setNewStep({
          waitTime: dat?.days.toString(),
          subject: dat?.subject,
        });
        setSelectedFields({
          emodProfileId: dat?.eModProfileId,
        });
        setBody(dat?.body || "");
      } else {
        toast.error(response.statusText);
      }
    } catch (error) {
      console.error("Error fetching step data: ", error);
    }
  };
  const handleEditSave = async () => {
    const newErrors: any = {};
    Object.keys(newStep).forEach((key) => {
      const error = validateField(key, newStep[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoadingStep(true)
      let campId = sessionStorage.getItem('campaignId')
      const response = await updateStep(newStep, selectedFields, stepNumber, editStepId, body, campId)
      try {
        if (response.status == 200) {
          toast.success("Step Updated Successfully")
          closeModal();
          fetchStep()
          handleClear()
          setEditModal(false)
        } else {
          toast.error(response.statusText)
          handleClear()
        }
      } catch (error) {
      } finally {
        setLoadingStep(false)
      }
    }
  }


  const handleDuplicate = (index: number) => {
    setSteps([...steps, { ...steps[index] }]);
  };

  const handleDelete = async (id: string) => {
    const response = await deleteStep(id)
    try {
      if (response.status == 200) {
        toast.success(response.data.message)
        fetchStep()
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {
    }
  };

  const [optionsData, setOptionsData] = useState<Record<string, CampaignOption[]>>({
    eModProfile: [],
  });

  const fetchOptions = async () => {
    try {
      const responses = await Promise.all(
        optionConfigs.map(async (config) => {
          const response = (await getOptions(config.key)) as CampaignOption[];
          return {
            key: config.key,
            options: response
              ? response
                .filter((item) => item.isActive)
                .map((item) => ({
                  _id: item._id,
                  fieldName: item.fieldName,
                })) as CampaignOption[]
              : [],
          };
        })
      );
      const mappedOptions = responses.reduce((acc, { key, options }) => {
        acc[key] = options;
        return acc;
      }, {} as Record<string, CampaignOption[]>);

      setOptionsData(mappedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleSelectChange = (field: keyof SelectedFields) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (field === "emodProfileId" && value) {
      setError((prevErrors) => ({ ...prevErrors, emodProfileId: "" }));
    }
    setSelectedFields((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setNewStep({
      ...newStep,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: error,
    });
  };



  const fetchStep = async () => {
    let campId = sessionStorage.getItem('campaignId')
    const response = await getAllStep(campId)
    try {
      if (response.status == 200) {
        setStepData(response.data || [])
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {

    }
  }


  const [imagePath, setImagePath] = useState("")


  const handleBody = async (value: string) => {
    const base64ImgRegex = /<img[^>]+src="data:image\/[^;]+;base64,[^">]*"/g;

    const base64Images = value.match(base64ImgRegex);
    const uploadPromises: Promise<{ imagePath: string | null; originalImgSrc: string }>[] = [];
    const imagePathMap: { [key: string]: string | null } = {};

    if (base64Images) {
      let updatedBody = value;
      base64Images.forEach(base64Img => {
        updatedBody = updatedBody.replace(base64Img, '');
      });

      updatedBody = updatedBody.trim().replace(/&gt;/g, '');
      setBody(updatedBody);

      for (const base64Img of base64Images) {
        const base64String = base64Img.match(/src="([^"]+)"/)?.[1];
        if (base64String) {
          const response = await fetch(base64String);
          const blob = await response.blob();
          const file = new File([blob], `image_${Date.now()}.png`, { type: blob.type });

          const uploadPromise = uploadImage(file)
            .then((response) => {
              const imagePath = response.data.imagePath;
              imagePathMap[base64Img] = imagePath;
              return { imagePath, originalImgSrc: base64Img };
            })
            .catch(() => {
              imagePathMap[base64Img] = null;
              return { imagePath: null, originalImgSrc: base64Img };
            });
          uploadPromises.push(uploadPromise);
        }
      }

      try {
        const uploadResponses = await Promise.all(uploadPromises);
        let anyImageUploaded = false;

        uploadResponses.forEach(({ imagePath }) => {
          if (imagePath) {
            toast.success("Image uploaded successfully.");
            anyImageUploaded = true;
          } else {
            toast.error("Failed to upload image.");
          }
        });

        if (anyImageUploaded) {
          for (const [originalImgTag, imagePath] of Object.entries(imagePathMap)) {
            if (imagePath) {
              const imgTag = `<img src="${imagePath}" alt="Uploaded Image"/>`;
              updatedBody += imgTag;
            }
          }
          updatedBody = updatedBody.replace(/&gt;/g, '').trim();
          setBody(updatedBody);
        }
      } catch (error) {
        console.error("Error during image upload:", error);
      }
    } else {
      setBody(value.trim().replace(/&gt;/g, ''));
    }
  };

  function createMarkup(content: string) {
    const sanitizedContent = he.decode(content.replace(/<img[^>]*>|(&nbsp;)|(<[^>]+>)/g, ""));
    const truncatedContent = sanitizedContent.length > 70
      ? sanitizedContent.slice(0, 70) + " ..."
      : sanitizedContent;

    return { __html: truncatedContent };
  }
  function createMarkupWithOutLimit(content: string) {
    const sanitizedContent = he.decode(content.replace(/<img[^>]*>|(&nbsp;)|(<[^>]+>)/g, ""));
    return { __html: sanitizedContent };
  }

  const handleCreateStep = () => {
    openModal()
    handleClear()
    setEditModal(false)
  }


  const handleNext = () => {
    setLoading(true)
    setTimeout(() => {
      window.scrollTo(0, 0);
      setActiveStep(4)
      setLoading(false)
    }, 1000);
  }

  const [campaignEdit, setCampaignEdit] = useState(false)
  useEffect(() => {
    const dat = sessionStorage?.getItem('campaignEdit')
    if (dat == "true") {
      setCampaignEdit(true)
    } else {
      setCampaignEdit(false)
    }
    fetchStep()
    fetchOptions()
  }, [activeStep])


  return (
    <Box
      textAlign="center"
      mt={8}
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="sm"
    >
      <Box mb={4}>
        {stepData.length > 0 && (
          <VerticalTimeline
            layout={"1-column-left"}
            className="demoCustomLine"
            lineColor={"#ccc"}
          >
            {stepData.map((step, index) => {
              const eModProfileId = step.eModProfileId;
              const matchedProfile = optionsData.eModProfile.find(
                (profile) => profile._id === eModProfileId
              );
              const displayName = matchedProfile ? matchedProfile.fieldName : eModProfileId;
              return (
                <VerticalTimelineElement
                  key={index}
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    border: "1px solid #ccc",
                    boxShadow: "none",
                    color: "#000",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  rgb(33, 150, 243)",
                  }}
                  icon={<StepIcon stepCount={index + 1} />} // Show step count in the icon
                  iconStyle={{ background: "none", color: "inherit" }}
                >
                  <Accordion allowToggle border={0} borderBottomWidth={0}>
                    <AccordionItem border={0}>
                      <AccordionButton
                        py={0}
                        alignItems={"center"}
                        _focus={{ outline: "none" }}
                        _hover={{ backgroundColor: "transparent" }}
                        _active={{ backgroundColor: "transparent" }}
                        border={0}
                        _expanded={{ border: "none" }}
                      >
                        <Box flex="1" textAlign="left">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                            }}
                          >
                            <Text width={20} variant="span" fontSize="20">
                              {String.fromCharCode(65 + index)}{" "}
                            </Text>

                            <Text variant="span" fontSize="20" fontWeight={"bold"}>
                              {step.days}
                            </Text>
                            <Text variant="span" fontSize="20" fontWeight={"bold"}>
                              {step.subject}
                            </Text>
                            <Text variant="span" fontSize="20" fontWeight={"bold"}>
                              {displayName}
                            </Text>
                            <Text
                              variant="span"
                              fontSize="20"
                              fontWeight="bold"
                              dangerouslySetInnerHTML={createMarkup(step.body)}
                            />
                            <Flex>

                              <Flex>
                                <Tooltip label="Edit" borderRadius={8} fontSize="sm">
                                  <Button
                                    alignItems="center"
                                    justifyContent="center"
                                    bg={bgButton}
                                    _hover={bgHover}
                                    _focus={bgFocus}
                                    _active={bgFocus}
                                    w="37px"
                                    h="37px"
                                    lineHeight="100%"
                                    mx={2}
                                    ms={0}
                                    borderRadius="10px"
                                    onClick={() => handleEdit(step.campaignStepId, step.stepNumber)}
                                  >
                                    <Icon as={FaRegEdit} color={iconColor} w="20px" h="20px" />
                                  </Button>
                                </Tooltip>
                              </Flex>

                              <Flex>
                                <Tooltip label="Delete" borderRadius={8} fontSize="sm">
                                  <Button
                                    alignItems="center"
                                    justifyContent="center"
                                    bg={bgButton}
                                    _hover={bgHover}
                                    _focus={bgFocus}
                                    _active={bgFocus}
                                    w="37px"
                                    h="37px"
                                    lineHeight="100%"
                                    mx={2}
                                    ms={0}
                                    borderRadius="10px"
                                    onClick={() => handleDelete(step.campaignStepId)}
                                  >
                                    <Icon as={FaRegTrashAlt} color={iconColor} w="20px" h="20px" />
                                  </Button>
                                </Tooltip>
                              </Flex>
                            </Flex>
                          </Box>
                        </Box>
                        <AccordionIcon mt={2} />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Box
                          borderWidth="1px"
                          borderRadius="md"
                          p={3}
                          bg="gray.50"
                          textAlign="start"
                        >
                          {parse(step.body)}
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                </VerticalTimelineElement>
              )
            })}
          </VerticalTimeline>
        )}
      </Box>

      <Box display="flex" justifyContent="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-clipboard-plus"
          width="70"
          height="70"
          viewBox="0 0 24 24"
          strokeWidth="0.8"
          stroke="#2c3e50"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
          <path d="M10 14h4" />
          <path d="M12 12v4" />
        </svg>
      </Box>
      <Button

        // onClick={onOpen} 
        onClick={() => handleCreateStep()}
        colorScheme="blue" mt={4}>
        <Icon as={AddIcon} mx={2} color="white" />
        Add Campaign Step
      </Button>

      {/* <AddCampaignStep /> */}

      {stepData.length > 0 &&
        <Button
          display={"flex"}
          justifyContent="flex-end"
          marginLeft="auto"
          width="auto"
          colorScheme="blue"
          mt={4}
          rightIcon={<ChevronRightIcon />}
          onClick={() => handleNext()}
        >
          {loading ? (
            <Flex alignItems='center'>
              <Text mr='2' >{campaignEdit ? 'Update & Next' : 'Save & Next'} </Text>
              <Spinner size='sm' mr='2' />
            </Flex>
          ) : (
            <>
              {campaignEdit ? 'Update & Next' : 'Save & Next'}
            </>
          )}
        </Button>
      }

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Flex pb={3} alignItems="center" justifyContent="space-between">
          <Text fontWeight={600} fontSize={20}>
            {editModal ? "Edit Step" : "Add Step"}
          </Text>
          <CloseButton onClick={closeModal} />
        </Flex>
        <FormControl id="waitTime" mb={4} isInvalid={!!errors.waitTime}>
          <FormLabel>Wait Time</FormLabel>
          <Input
            name="waitTime"
            placeholder="Enter wait time in days"
            value={newStep.waitTime}
            onChange={handleInputChange}
          />
          {errors.waitTime && <Box color="red.500">{errors.waitTime}</Box>}
        </FormControl>

        {(stepData.length == 0 || stepNumber == 1) &&
          <FormControl id="subject" mb={4} isInvalid={!!errors.subject}>
            <FormLabel>Subject</FormLabel>
            <Input
              type="text"
              name="subject"
              className="subject"
              placeholder="Enter subject"
              value={newStep.subject}
              onChange={handleInputChange}
            />
            {errors.subject && <Box color="red.500">{errors.subject}</Box>}
          </FormControl>
        }

        <FormControl id="emod-profile" isInvalid={!!error.emodProfileId}>
          <FormLabel>eMod Profile</FormLabel>
          <Select
            placeholder="Select eMod profile"
            value={selectedFields.emodProfileId}
            onChange={handleSelectChange("emodProfileId")}
            name="eMod"
          >
            {optionsData["eModProfile"].map((option) => (
              <option key={option._id} value={option._id}>
                {option.fieldName}
              </option>
            ))}
          </Select>
          {error.emodProfileId && <Box color="red.500">{error.emodProfileId}</Box>}

        </FormControl>

        <FormControl id="body" mb={4} isInvalid={!!errors.subject} mt={3}>
          <FormLabel>Body</FormLabel>
          <Editor handleBody={handleBody} body={body} />
        </FormControl>

        <Flex justifyContent={'flex-end'}>
          <Button colorScheme="blue" mr={3} onClick={editModal ? handleEditSave : handleSave}>
            {loadingStep ? (
              <Flex alignItems='center'>
                <Text mr='2' >Save </Text>
                <Spinner size='sm' mr='2' />
              </Flex>
            ) : (
              'Save '
            )}
          </Button>
          <Button variant="outline" borderColor={"#ccc"} onClick={closeModal}>
            Cancel
          </Button>
        </Flex>
      </Modal>

    </Box >
  );
}

export default AddSteps