import { Box } from '@chakra-ui/react'
import React from 'react'
import ContactListTable from './ContactListTable/ContactListTable'

export default function GlobalContactList() {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
    <ContactListTable/>
  </Box>  )
}
