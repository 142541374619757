import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input, Grid, GridItem,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner, // Import Spinner for loading indication
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth2.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useApiContext } from "contexts/apiContext";
import { signIn, signUp } from "libs/apiFunctions";
import { toast } from "react-toastify";
import { useAuthContext } from "contexts/authContext";

interface formDataType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  [key: string]: string | boolean; // Adding index signature to support dynamic key access
}

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "black";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const { setUserData } = useApiContext();

  const [formData, setFormData] = useState<formDataType>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false); // Loading state
  const history = useHistory();

  const { isLoggedIn, setLogin } = useAuthContext();

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const validateField = (name: string, value: any): string => {
    let error = "";
    switch (name) {
      case "firstName":
        if (!value) {
          error = "Please enter first name";
        }
        break;
      case "lastName":
        if (!value) {
          error = "Please enter last name";
        }
        break;
      case "email":
        if (!value) {
          error = "Please enter email address";
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          error = "Please enter valid email address";
        }
        break;
      case "password":
        if (!value || value.trim() === "") {
          error = "Please enter password";
        }
        break;
      default:
        error = "";
    }
    return error;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    const newErrors: any = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      let response = await signUp(formData);
      console.log("response ", response)
      if (response?.statusMessage === "SUCCESS") {
        setTimeout(() => {
          setLoading(false);
          toast.success(response?.message);
          history.push("/auth/sign-in");
        }, 1000);
      } else {
        setLoading(false);
        toast.info(response?.message);
      }
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        // mb={{ base: "0px", md: "30px" }}
        px={{ base: "25px", md: "0px" }}
        // mt={{ base: "0px", md: "15vh" }}
        flexDirection="column"

      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="0px">
            Sign Up
          </Heading>
          <Text
            mb="10px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Create an account or Sign in!          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "460px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
        // mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>

            <Box my={1.5}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                First Name<Text color='red'>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter first name"
                fontWeight="500"
                size="lg"
                name="firstName"
                value={formData.firstName} // Bind value to state
                onChange={handleInputChange} // Handle change
              />
              {errors.firstName && (
                <Box color="red.500" py={0.5}>
                  {errors.firstName}
                </Box>
              )}
            </Box>
            <Box my={1.5}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Last Name<Text color='red'>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter last name"
                fontWeight="500"
                size="lg"
                name="lastName"
                value={formData.lastName} // Bind value to state
                onChange={handleInputChange} // Handle change
              />
              {errors.lastName && (
                <Box color="red.500" py={0.5}>
                  {errors.lastName}
                </Box>
              )}
            </Box>

            <Box my={1.5} pt={1}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color='red'>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="Enter email address"
                fontWeight="500"
                size="lg"
                name="email"
                value={formData.email} // Bind value to state
                onChange={handleInputChange} // Handle change
              />
              {errors.email && (
                <Box color="red.500" py={0.5}>
                  {errors.email}
                </Box>
              )}
            </Box>

            <Box>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color='red'>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Enter password"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  name="password"
                  value={formData.password} // Bind value to state
                  onChange={handleInputChange} // Handle change
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {errors.password && (
                <Box color="red.500" py={0.5}>
                  {errors.password}
                </Box>
              )}
            </Box>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              my="20px"
              onClick={handleSubmit} // Handle form submission
            >
              {loading ? (
                <Flex alignItems="center">
                  <Text mr="2">Sign Up </Text>
                  <Spinner size="sm" mr="2" />
                </Flex>
              ) : (
                "Sign Up"
              )}
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already Registered ?
              {/* <NavLink to="/auth/sign-in"> */}
              <Text
                onClick={() => history.push('/auth/sign-in')}
                color={textColorBrand}
                as="span"
                ms="5px"
                fontWeight="500"
                cursor={"pointer"}
              >
                Sign In
              </Text>
              {/* </NavLink> */}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
